interface ReturnProps {
  width: number | null;
  height: number | null;
}

export type SrcProp = string | MediaType;

const getDimensionsFromUrl = (url: string): ReturnProps => {
  const dimensions = url.match(/(\d+)x(\d+)/);

  if (!dimensions) {
    return { width: null, height: null };
  }

  const [width, height] = dimensions[0].split('x').map(Number);

  return { width, height };
};

export const getDimensions = ({
  sourceUrl,
  width,
  height,
  imageRef,
}): number[] => {
  if (sourceUrl && (!width || !height)) {
    const { width: extractedWidth, height: extractedHeight } =
      getDimensionsFromUrl(sourceUrl);

    if (extractedWidth && extractedHeight)
      return [extractedWidth, extractedHeight];

    const parentElement = imageRef.current?.closest('div');
    const parentWidth = parentElement?.clientWidth || 0;
    const parentHeight = parentElement?.clientHeight || 0;

    if (parentWidth && parentHeight) {
      return [parentWidth, parentHeight];
    }
  }

  if (!width || !height) return [50, 50];

  const castedWidth = typeof width === 'number' ? width : parseInt(width);
  const castedHeight = typeof height === 'number' ? height : parseInt(height);

  return [castedWidth, castedHeight];
};

type Resolution = {
  height: number;
  width: number;
};

type ImageResolutions = {
  large: Resolution;
  medium: Resolution;
  medium_large: Resolution;
  original: Resolution;
  thumbnail: Resolution;
};

type ImageObject = {
  id: number;
  medium: string;
  medium_large: string;
  large: string;
  original: string;
  srcset?: string;
  thumbnail: string;
  resolution: ImageResolutions;
};

// "media": {
// "image": {
//   "id": 414677,
//   "resolution": {
//     "original": {
//       "width": 1500,
//       "height": 1500
//     },
//     "medium": {
//       "width": 400,
//       "height": 400
//     },
//     "large": {
//       "width": 600,
//       "height": 600
//     },
//     "thumbnail": {
//       "width": 300,
//       "height": 300
//     },
//     "medium_large": {
//       "width": 768,
//       "height": 768
//     }
//   },
//   "original": "https://cdn.pkb.local/wp-content/uploads/2023/03/image_20230330_104339690.png",
//   "medium": "https://cdn.pkb.local/wp-content/uploads/2023/03/image_20230330_104339690-400x400.png",
//   "large": "https://cdn.pkb.local/wp-content/uploads/2023/03/image_20230330_104339690-600x600.png",
//   "thumbnail": "https://cdn.pkb.local/wp-content/uploads/2023/03/image_20230330_104339690-300x300.png",
//   "medium_large": "https://cdn.pkb.local/wp-content/uploads/2023/03/image_20230330_104339690-768x768.png",
//   "srcset": "https://cdn.pkb.local/wp-content/uploads/2023/03/image_20230330_104339690-400x400.png 400w, https://cdn.pkb.local/wp-content/uploads/2023/03/image_20230330_104339690-600x600.png 600w, https://cdn.pkb.local/wp-content/uploads/2023/03/image_20230330_104339690-300x300.png 300w, https://cdn.pkb.local/wp-content/uploads/2023/03/image_20230330_104339690-768x768.png 768w, https://cdn.pkb.local/wp-content/uploads/2023/03/image_20230330_104339690.png 1600w"
// }

export const getSrcUrl = (
  src: SrcProp,
  requestedWidth?: number,
  requestedHeight?: number
): string => {
  if (typeof src === 'string') {
    return src;
  }

  if (typeof src?.image === 'string') {
    return src.image;
  }

  if (typeof src?.image === 'object') {
    const image = src.image;

    if (!image.resolution) {
      return '';
    }

    // Ordered list of sizes from resolution object sorted by width from smallest to largest
    const sizes = Object.entries(image.resolution as ImageResolutions)
      .map(([key, value]: [string, Resolution]) => ({
        key,
        width: value.width,
        height: value.height,
      }))
      .sort((a, b) => a.width - b.width);

    // If no dimensions specified, return medium size or smallest available
    if (!requestedWidth && !requestedHeight) {
      const mediumUrl = image.medium;
      if (mediumUrl) return mediumUrl;

      const smallestSize = sizes[0];
      return image[smallestSize.key as keyof ImageObject] || '';
    }

    // Find the smallest size that's larger than both requested dimensions
    const requestedW = requestedWidth || 0;
    const requestedH = requestedHeight || 0;

    const appropriate = sizes.find(
      size => size.width >= requestedW && size.height >= requestedH
    );

    if (appropriate) {
      const selectedUrl = image[appropriate.key as keyof ImageObject];
      return selectedUrl || '';
    }

    // If no appropriate size found, return the largest available size
    const largestSize = sizes[sizes.length - 1];
    return image[largestSize.key as keyof ImageObject] || '';
  }

  return '';
};
