export const getLocalePath = (
  locale: string | undefined,
  lng: string | undefined
) => {
  if (locale && locale !== 'en') {
    return `/${locale}`;
  }

  if (lng && lng !== 'en') {
    return `/${lng}`;
  }

  return '';
};

export const addLocalePath = (
  locale: string | undefined,
  url: string | undefined
) => {
  if (!url) return '/';

  // Remove any leading slash(es)
  const cleanUrl = url.replace(/^\/+/, '');

  if (locale && locale !== 'en') {
    return `/${locale}/${cleanUrl}`;
  }

  return `/${cleanUrl}`;
};
