'use client';

import React from 'react';

import styled from 'styled-components';

import { useAuthContext } from '../AuthProvider/context';
// import Button from '../Button';
import Icon from '../Icon';
import { urlBuilder } from '@/utils/urlBuilder';

const FloatingButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  min-height: 2rem;
  top: 1rem;
  right: 1rem;
  z-index: 1;

  background-color: ${({ theme }) => theme.custom.colors.brand};
  color: white;
  font-weight: bold;
  padding: 0.2rem 0.5rem;
  box-shadow: 0.1rem 0.1rem 1rem 0.1rem rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 0.75rem;

  &:hover {
    background-color: white;
    color: ${({ theme }) => theme.custom.colors.brand};
  }
`;

const MAP_TYPE_LABEL = {
  products: 'product',
  category: 'category',
  helps: 'article',
  guides: 'guide',
  steps: 'step',
  tags: 'product',
  manual: 'category',
  help: 'article',
  guide: 'guide',
};

export default function EditInAdminButton({ visible, item, parentSlug = '' }) {
  const { can, actions, isUserLoggedIn } = useAuthContext(); // isMedical

  const url = React.useMemo(() => {
    const url = urlBuilder({
      item: { slug: item?.slug, type: item?.type, parentSlug },
      admin: true,
      silent: true,
    });
    return url;
  }, [item]);

  if (
    !isUserLoggedIn ||
    !can(actions.visit_admin) ||
    url.includes('no-type-or-slug') ||
    url.includes('no-item')
  ) {
    return null;
  }

  if (!visible) {
    return (
      <FloatingButton>
        <Icon name="pencil" style="solid" size="sm" />
      </FloatingButton>
    );
  }

  return (
    <FloatingButton
      onClick={() => {
        window.location.href = url;
      }}
    >
      Edit {MAP_TYPE_LABEL?.[item?.type] || ''} in Admin
    </FloatingButton>
  );
}
