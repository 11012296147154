/**
 * @description output to stdout and stderr are consumed by Loki
 */
import { checkPropsType, LogLevel } from './checkPropsType';
import { LOG_TYPE, LogProps, isDebugMode, logger } from './logger';

const loggerFactory = (level: LogLevel) => {
  return (props: LogProps | string | Event) => checkPropsType(props, level);
};

const throwOrLog = (options: LogProps) => {
  if (isDebugMode) throw new Error(JSON.stringify(options, null, 2));
  else logger(options);
};

/**
 * @example simple: log.info('message')
 * @example condition: log.error({ event: 'message', condition: false === true })
 * @example stringify: log.warning({ event: 'message', condition: false === true })
 * @example array: log.warning([1,2,3])
 */
export const log = {
  throw: throwOrLog,
  error: loggerFactory(LOG_TYPE.error),
  info: loggerFactory(LOG_TYPE.info),
  warning: loggerFactory(LOG_TYPE.warning),
  debug: loggerFactory(LOG_TYPE.debug),
  cache: loggerFactory(LOG_TYPE.cache),
};
