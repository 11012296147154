import {
  faExclamationTriangle,
  faHome,
  faChevronDown,
  faEnvelope,
  faInfoCircle as faInfoCircleLight,
  faThumbtack,
  faUser,
  faXmark,
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
  faAngleDown as faAngleDownLight,
  faAngleRight as faAngleRightLight,
  faBook,
  faLink,
  faText,
  faArrowDownToLine,
  faSearch,
  faDownload,
} from '@fortawesome/pro-light-svg-icons';
import { faChevronRight as faChevronRightRegular } from '@fortawesome/pro-regular-svg-icons';
import {
  faBackward,
  faForward,
  faLeft,
  faRight,
  faSpinnerThird,
  faCompress,
  faCompressArrowsAlt,
  faComment,
  faExclamationCircle,
  faInfoCircle as faInfoCircleSolid,
  faCheckCircle,
  faAngleDown,
  faAngleRight,
  faCaretDown,
  faCaretUp,
  faTimes,
  faCheck,
  faChevronRight as faChevronRightSolid,
  faChevronDown as faChevronDownSolid,
  faExclamation,
  faShoppingCart,
  faCircleXmark,
  faTriangleExclamation,
  faPencil,
} from '@fortawesome/pro-solid-svg-icons';

export default [
  faBackward,
  faExclamationTriangle,
  faForward,
  faHome,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronRightSolid,
  faChevronRightRegular,
  faChevronDownSolid,
  faLeft,
  faEnvelope,
  faRight,
  faSpinnerThird,
  faThumbtack,
  faUser,
  faCompress,
  faCompressArrowsAlt,
  faXmark,
  faComment,
  faExclamationCircle,
  faCheckCircle,
  faInfoCircleLight,
  faInfoCircleSolid,
  faInfoCircle,
  faShoppingCart,
  faAngleDown,
  faAngleRight,
  faCaretDown,
  faCaretUp,
  faTimes,
  faCheck,
  faExclamation,
  faAngleDownLight,
  faAngleRightLight,
  faCircleXmark,
  faTriangleExclamation,
  faPencil,
  faBook,
  faLink,
  faText,
  faArrowDownToLine,
  faSearch,
  faDownload,
] as const;
