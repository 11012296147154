import dayjs from 'dayjs';
import { decode } from 'html-entities';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';

import 'dayjs/locale/cs';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/es';
import 'dayjs/locale/pl';
import 'dayjs/locale/fr';
import 'dayjs/locale/ja';

type SanitizeOptions = {
  allowedTags: string[] | false;
  allowedAttributes: Record<string, unknown> | false;
  allowVulnerableTags: boolean;
};

const baseTagSubset: string[] = [
  'address',
  'article',
  'aside',
  'footer',
  'header',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hgroup',
  'main',
  'nav',
  'section',
  'blockquote',
  'dd',
  'div',
  'dl',
  'dt',
  'figcaption',
  'figure',
  'hr',
  'li',
  'main',
  'ol',
  'p',
  'pre',
  'ul',
  'a',
  'abbr',
  'b',
  'bdi',
  'bdo',
  'br',
  'cite',
  'code',
  'data',
  'dfn',
  'em',
  'i',
  'kbd',
  'mark',
  'q',
  'rb',
  'rp',
  'rt',
  'rtc',
  'ruby',
  's',
  'samp',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'time',
  'u',
  'var',
  'wbr',
  'caption',
  'col',
  'colgroup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
  'img',
  'video',
];

export function parseSanitizedHTML(
  HtmlString: string,
  strict: boolean | 'no-html' = false
): ReturnType<typeof parse> {
  const strictAllowedTags: string[] = strict === 'no-html' ? [] : baseTagSubset;
  const sanitizeOptions: SanitizeOptions = {
    allowedTags: strict ? strictAllowedTags : false,
    allowedAttributes: strict === 'no-html' ? {} : false,
    allowVulnerableTags: strict === true ? false : true,
  };

  const sanitizedHtml: string = sanitizeHtml(HtmlString, sanitizeOptions);

  return parse(sanitizedHtml);
}

export const preloadImages = imageUrls => {
  imageUrls.forEach(url => {
    const img = new Image();
    img.src = url;
  });
};

export const formatDateForLocale = (locale, dateString) => {
  const dateFormats = {
    en: 'MMMM DD, YYYY',
    cs: 'DD.MM.YYYY',
    de: 'DD.MM.YYYY',
    it: 'DD.MM.YYYY',
    es: 'DD.MM.YYYY',
    pl: 'DD.MM.YYYY',
    fr: 'DD/MM/YYYY',
    jp: 'MM/DD/YYYY',
  };

  return dayjs(dateString).locale(locale).format(dateFormats[locale]);
};

export const findProductBySlug = (infoData, productSlug) => {
  for (const product of infoData.products) {
    const child = product.childrens?.find(child => child.slug === productSlug);
    if (child) {
      return child;
    }
  }
  return null;
};

export function findProductByTag(
  data: Product[],
  tagSlug: string
): Product | null {
  // Recursively search through all nested children
  function searchTag(items: Product[]): Product | null {
    for (const item of items) {
      // Check if current item matches the slug
      if (item.slug === tagSlug) {
        return item;
      }
      // Check children if they exist
      if (item.childrens && item.childrens.length > 0) {
        const result = searchTag(item.childrens);
        if (result) return result;
      }
    }
    return null;
  }

  return searchTag(data);
}

export const replaceLocalUrl = (input: string) => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const localUrls = ['https://pkb.local/', 'https://cdn.pkb.local/'];
  const cdnUrl = 'https://cdn.help.prusa3d.com/';

  if (!input) {
    return input;
  }

  if (isDevelopment) {
    localUrls.forEach(localUrl => {
      input = input.replace(new RegExp(localUrl, 'g'), cdnUrl);
    });
  }

  return input;
};

export const getCookie = (name: string): string | null => {
  if (typeof document === 'undefined') {
    return null;
  }
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
};

export const deleteCookie = (name: string): void => {
  if (typeof document !== 'undefined') {
    document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

export const containsHTML = (str: string): boolean => {
  const pattern = /<\/?[a-z][\s\S]*>/i;
  return pattern.test(str);
};

export const descriptionCute = string => {
  if (!string) return '';

  const stripped = decode(string.replace(/<[^>]*>?/gm, ''));
  const index = stripped.indexOf(' ', 175);
  return index === -1 ? stripped : stripped.substring(0, index) + '…';
};

export const getYoutubeId = (url: string) => {
  const [a, , b] = url
    .replace(/(>|<)/gi, '')
    .split(
      /^.*(?:(?:youtu\.?be(\.com)?\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/
    );
  if (b !== undefined) {
    return b.split(/[^0-9a-z_-]/i)[0];
  } else {
    return a;
  }
};

export const detectMobileDevice = () => {
  const ua = navigator.userAgent.toLowerCase();
  const hasTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const isPhone =
    /iphone|ipod|android.*mobile|blackberry|windows phone/i.test(ua) ||
    (hasTouch && screenWidth < 600);

  const isTablet =
    /ipad/i.test(ua) ||
    (/macintosh/i.test(ua) && hasTouch) ||
    (/android/i.test(ua) && !/mobile/i.test(ua)) ||
    (hasTouch && screenWidth >= 600 && screenWidth <= 1200);

  return {
    isMobile: isPhone || isTablet,
    isPhone: isPhone,
    isTablet: isTablet,
    isDesktop: !(isPhone || isTablet),
    screenWidth: screenWidth,
    hasTouch: hasTouch,
  };
};
