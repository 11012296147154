import { LOG_TYPE, LogProps } from './logger';
import { logger } from './logger';

export type LogLevel = (typeof LOG_TYPE)[keyof typeof LOG_TYPE];

export const checkPropsType = (
  props: LogProps | string | Event,
  level: LogLevel
) => {
  let enrichedProps = {} as LogProps;

  if (typeof props === 'string') {
    enrichedProps.event = props;
  } else if (props instanceof Event) {
    enrichedProps.event = props.type;
    enrichedProps.message = props.toString();
  } else {
    if (Array.isArray(props)) {
      enrichedProps.event = 'array';
      enrichedProps.message = JSON.stringify(props);
    } else {
      enrichedProps = Object.assign(enrichedProps, props);
    }
  }

  return logger({ ...enrichedProps, level });
};
