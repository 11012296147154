'use client';

import React from 'react';

import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import styled from 'styled-components';

import EditInAdminButton from '../../EditInAdminButton';
import Image from '@/ui/components/Image';
import Link from '@/ui/components/Link';
import { device } from '@/ui/styles/breakpoints';

const CardWrap = styled.div`
  display: flex;
  height: 100%;

  a {
    width: 100%;

    &:hover {
      h3 {
        text-decoration: underline;
      }
    }
  }
`;

const CardContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;

  h3 {
    color: #2a2a2a;
    margin-top: 10px;
    text-align: center;

    @media ${device.min.md} {
      white-space: nowrap;
    }
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }
`;

/**
 * @todo log when null
 * @todo hover
 */
const ProductCard = ({ item }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  if (!item) return null;

  const { id, title, linkUrl, media } = item;

  return (
    <Grid item key={id} xs={6} sm={4} md={2}>
      <CardWrap>
        <Link href={linkUrl}>
          <CardContent
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {item?.slug && (
              <EditInAdminButton item={item} visible={isHovered} />
            )}
            <Image src={media} alt={title} width={140} height={140} nextImage />
            <Typography variant="body1" component="h3">
              {title}
            </Typography>
          </CardContent>
        </Link>
      </CardWrap>
    </Grid>
  );
};

export default ProductCard;
