/**
 * @module logger
 * @description output to stdout and stderr are consumed by Loki
 */
import { consoleWithColor } from './consoleWithColor';

type LogTypeKeys = keyof typeof LOG_TYPE;

export interface LogProps {
  condition?: boolean;
  event: string;
  message?: string;
  labels?: object;
  level?: (typeof LOG_TYPE)[LogTypeKeys];
  stringify?: object;
  [key: string]: object | string | number | boolean | undefined;
}

export const LOG_TYPE = {
  info: 'info',
  warning: 'warning',
  error: 'error',
  success: 'success',
  cache: 'cache',
  debug: 'debug',
} as const;

export const isDebugMode = process.env.NEXT_PUBLIC_DEBUG_MODE === 'true';

/**
 * @function log
 * async/await is used to ensure that the logs are written in NextJS middleware
 * @example see README.md or ./index.ts
 */
export const logger = ({
  condition = undefined,
  event,
  message = undefined,
  labels = {},
  level = LOG_TYPE.info,
  stringify,
  ...rest
}: LogProps) => {
  if (condition !== null && condition != undefined && !condition) return;
  if (level === LOG_TYPE.debug && !isDebugMode) return; // only log debug messages if debug mode is enabled in env

  const timestamp = new Date().toISOString();
  const enviroment = process?.env?.NODE_ENV || 'development';

  let valueStringifiedToJson = '';
  if (stringify) {
    const jsonString = JSON.stringify(stringify);
    valueStringifiedToJson = `[JSON=${jsonString}]`;
  }

  const output = {
    level,
    event,
    ...(message && { message }), // add message only if it exists
    timestamp,
    labels: {
      ...labels,
      level,
      enviroment,
    },
    ...(valueStringifiedToJson && { valueStringifiedToJson }), // add valueStringifiedToJson only if it exists
    ...rest,
  };

  consoleWithColor(output, level);
};
