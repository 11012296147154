import { log } from '@/utils/logger';

// paths
const MAP_TYPE_TO_PATH = {
  products: '/product',
  topic: '/product',
  category: '/category',
  helps: '/article',
  guides: '/guide',
  tags: '/tag',
  tooltips: '/glossary',
  relations: '/relation',
  keywords: '/keyword',
  user: '/user',
  manual: '/manual',
  help: '/article',
  guide: '/guide',
  post_tag: '/tag',
  download: '/downloads',
  downloads: '/downloads',
  tooltip: '/glossary',
  relation: '/relation',
  keyword: '/keyword',
  materials: '/filament-material-guide',
};

const ADMIN_PATH = '/admin';

// `${match.url}/``${match.url}/error``${match.url}/product-pages``${match.url}/product-page/:viewId``${match.url}/tag/:tag``${match.url}/tags``${match.url}/keywords``${match.url}/keyword/:relation``${match.url}/relations``${match.url}/relation/:relation``${match.url}/tooltips``${match.url}/glossary/:slug``${match.url}/guide/:slug/:chapter([a-zA-Z0-9-]+_[0-9]+)?``${match.url}/guides/:slug``${match.url}/categories``${match.url}/category/:category/tableOnly``${match.url}/documentation/:category/tableOnly``${match.url}/category-detail/:category``${match.url}/category/:category``${match.url}/article/:slug``${match.url}/redirects``${match.url}/redirect/:id``${match.url}/search/``${match.url}/user/:id``${match.url}/users``${match.url}/redirects``${match.url}/maintenance``${match.url}/socket``${match.url}/translations`;
export const MAP_TYPE_TO_PATH_ADMIN = {
  products: `${ADMIN_PATH}/tag`,
  category: `${ADMIN_PATH}/category`,
  helps: `${ADMIN_PATH}/article`,
  guides: `${ADMIN_PATH}/guide`,
  tags: `${ADMIN_PATH}/tag`,
  manual: `${ADMIN_PATH}/category`,
  help: `${ADMIN_PATH}/article`,
  guide: `${ADMIN_PATH}/guide`,
  steps: `${ADMIN_PATH}/#PARENT#/steps`,
  // topic: `${ADMIN_PATH}/product`,
  // downloads: `${ADMIN_PATH}/downloads`,
  // tooltips: `${ADMIN_PATH}/glossary`,
  // relations: `${ADMIN_PATH}/relation`,
  // keywords: `${ADMIN_PATH}/keyword`,
  // user: `${ADMIN_PATH}/user`,
  // post_tag: `${ADMIN_PATH}/tag`,
  // download: `${ADMIN_PATH}/downloads`,
  // tooltip: `${ADMIN_PATH}/glossary`,
  // relation: `${ADMIN_PATH}/relation`,
  // keyword: `${ADMIN_PATH}/keyword`,
};

// fallback on unknown type
const defaultValue = '/404';

const handler = {
  get: function (target, property) {
    if (property in target) {
      return target[property];
    }

    log.error(`Path for type ${property} is missing`);
    return defaultValue;
  },
};

export const paths = new Proxy(MAP_TYPE_TO_PATH, handler);
