'use client';

import React from 'react';

import NextJsImage from 'next/image';

import { getDimensions, getSrcUrl, SrcProp } from './utils';
import { replaceLocalUrl } from '@/utils/helpers';

interface ImageProps {
  src: SrcProp;
  alt: string;
  title?: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
  nextImage?: boolean;
}

interface ImageOptions {
  src: string;
  width: number;
  height: number;
  alt: string;
  title?: string;
  loading: 'lazy' | 'eager';
  ref: React.RefObject<HTMLImageElement>;
  style?: React.CSSProperties;
}

/**
 * @todo placeholder
 * @todo cdn, cloudflare loader
 * @todo use nextimage
 */
export default function Image({
  src,
  alt,
  title,
  width,
  height,
  style,
  nextImage = false,
}: ImageProps) {
  const imageRef = React.useRef<HTMLImageElement>(null);

  /**
   * get src URL
   */
  const sourceUrl = React.useMemo(
    () => getSrcUrl(src, width, height),
    [src, width, height]
  );

  /**
   * get dimensions of the image
   */
  const [processedWidth, processedHeight] = React.useMemo(
    () => getDimensions({ sourceUrl, width, height, imageRef }),
    [sourceUrl, width, height, imageRef]
  );

  if (!sourceUrl) return <div title="missing image">&nbsp;</div>;

  const imageOptions: ImageOptions = {
    src: replaceLocalUrl(sourceUrl),
    width: processedWidth,
    height: processedHeight,
    alt: alt,
    title: title,
    loading: 'lazy',
    ref: imageRef,
    style: style,
  };

  if (nextImage) {
    return (
      <NextJsImage
        src={imageOptions.src}
        width={imageOptions.width}
        height={imageOptions.height}
        alt={imageOptions.alt}
        title={imageOptions.title}
        loading={imageOptions.loading}
        ref={imageOptions.ref}
        style={imageOptions.style}
      />
    );
  }

  return <img {...imageOptions} />;
}
