import React from 'react';

import type { UserData } from '@/lib/authentification/decodeJwt';
import type { CanFunctionType } from '@/lib/permissions';

interface AuthContextType {
  user: UserData | null;
  // token: string | null;
  isUserLoggedIn: boolean;
  can: CanFunctionType;
  actions: { [key: string]: string };
  isMedical: boolean;
}

export const initialState = {
  user: null,
  // token: null,
  isUserLoggedIn: false,
  can: () => false,
  actions: {},
  isMedical: false,
};

export const AuthContext = React.createContext<AuthContextType>(initialState);

export const useAuthContext = () => React.useContext(AuthContext);
