import React from 'react';

// import MuiLink from '@mui/material/Link';
import NextLink from 'next/link';
import { useLocale } from 'next-intl';

import { LocaleType } from '@/i18n';
// import persistentState, { PERSISTENT_STATE_KEYS } from '@/lib/persistentState';
import { urlBuilder } from '@/utils/urlBuilder';

type LinkItem = {
  title: string;
  type: string;
  slug?: string;
  hashAnchor?: string;
};

interface LinkProps {
  href?: string;
  item?: LinkItem;
  locale?: false | LocaleType;
  children?: React.ReactNode;
}

/**
 * Link component
 * @description accepts WP item, href string or children
 * @example <Link item={item} />
 * @example <Link href="/some-url">Josef</Link>
 */
export default function Link({
  href,
  item,
  locale,
  children,
}: LinkProps): JSX.Element {
  const currentLocale = useLocale();

  const url = React.useMemo(() => {
    if (href) {
      return `${href}`;
    }

    // for links to anchors
    if (item?.hashAnchor) {
      return `#${item.hashAnchor}`;
    }

    // for post types
    if (item) {
      return urlBuilder({ item, locale: currentLocale });
    }

    return '#';
  }, [href, item]);

  return (
    <NextLink href={url} prefetch={true} locale={locale || currentLocale}>
      {children || item?.title || 'Link'}
    </NextLink>
  );
}
