'use client';
import React from 'react';

import {
  IconName,
  IconProp,
  IconPrefix,
  SizeProp,
} from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DEFAULT_STYLE, PREFIXES } from '@/ui/components/Icon/constants';

// initialize predefined set of fa icons for <Icon /> component
import '@/ui/components/Icon/initializeIcons';

interface IconProps {
  className?: string;
  name: IconName;
  onClick?: (event?) => void;
  size?: SizeProp;
  spin?: boolean;
  style?: keyof typeof PREFIXES;
}

/**
 * Icon component
 * @
 * icons are defined in frontend/src/ui/Icon/iconsInUse.ts
 * @description This component is a wrapper for FontAwesomeIcon component
 * @example home light icon: <Icon name="home" />
 * @example home regular icon: <Icon name="home" style="regular" />
 * @todo refactor as IconPrefix
 */
const Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ name, style = DEFAULT_STYLE, onClick, ...props }, ref) => {
    if (!name) throw new Error('Icon name is required');

    const faIcon: IconProp = [PREFIXES[style] as IconPrefix, name];

    return (
      <FontAwesomeIcon icon={faIcon} onClick={onClick} {...props} ref={ref} />
    );
  }
);

Icon.displayName = 'Icon';

export default Icon;
