export const singleLineLog = content => {
  let singleLineContent;
  if (typeof content === 'object') {
    try {
      singleLineContent = JSON.stringify(content).replace(/\n/g, ' ');
    } catch (e) {
      singleLineContent = String(content).replace(/\n/g, ' ');
    }
  } else {
    singleLineContent = String(content).replace(/\n/g, ' ');
  }

  return console.log(singleLineContent);
};
