import { paths, MAP_TYPE_TO_PATH_ADMIN } from './constants';
import { getLocalePath } from './utils';
import { LocaleType } from '@/i18n';
import { log } from '@/utils/logger';

type Nullish = null | undefined;

interface UrlBuilderProps {
  item: {
    type: string;
    slug?: string;
    lng?: string;
    parentSlug?: string;
    parent?: number;
  };
  locale?: LocaleType;
  params?: string | { [key: string]: string | Nullish };
  admin?: boolean;
}

const getPath = ({ item, params, admin }) => {
  let path;
  const { type, slug, parentSlug } = item;

  if (admin) {
    path = `${MAP_TYPE_TO_PATH_ADMIN[type]}/${slug}${
      params ? `?${params}` : ''
    }`;

    if (type === 'steps') {
      path = path.replace('#PARENT#', `guide/${parentSlug}`);
    }
  } else {
    if (type === 'useful_links') {
      path = item.excerpt;
    } else if (type === 'downloads' || type === 'download') {
      path = `${paths[type]}`;
    } else if (type === 'topic' && parentSlug) {
      path = `${paths[type]}/${parentSlug}/${slug}${
        params ? `?${params}` : ''
      }`;
    } else if (type === 'manual' && parentSlug) {
      path = `${paths[type]}/${slug}/${parentSlug}${
        params ? `?${params}` : ''
      }`;
    } else {
      path = `${paths[type]}/${slug}${params ? `?${params}` : ''}`;
    }
  }

  return path;
};

export const urlBuilder = ({
  item,
  locale,
  params = '',
  admin = false,
  silent = false,
}: UrlBuilderProps & { silent?: boolean }): string => {
  if (!item) {
    if (!silent) {
      log.warning({
        event: 'urlBuilder',
        message: 'Item is missing',
        stringyfy: {
          locale,
          params,
          admin,
        },
      });
    }
    return 'no-item';
  }
  const { type, slug, lng } = item;

  if (!type || !slug) {
    if (!silent) {
      log.warning({
        event: 'urlBuilder',
        message: 'Type or slug is missing',
        stringyfy: {
          item,
          locale,
          params,
          admin,
        },
      });
    }
    return 'no-type-or-slug';
  }

  if (params && typeof params === 'object') {
    const paramsArray = Object.entries(params).map(
      ([key, value]) => `${key}=${value}`
    );
    params = paramsArray.join('&');
  }

  const path = getPath({
    item,
    params,
    admin,
  });

  if (!locale && !lng && !path.includes('/admin/')) {
    if (!silent) log.warning(`Language not specified for link: ${path}`);
    return `${path}`;
  }

  const pathLocale = getLocalePath(locale, lng);
  return `${pathLocale}${path}`;
};
