import { singleLineLog } from './singleLineLog';

const COLORS = {
  red: '\x1b[31m',
  yellow: '\x1b[33m',
  green: '\x1b[32m',
  blue: '\x1b[34m',
  cyan: '\x1b[36m',
  magenta: '\x1b[35m',
  reset: '\x1b[0m',
};

const COLORS_BY_TYPE = {
  info: COLORS.blue,
  warning: COLORS.yellow,
  error: COLORS.red,
  success: COLORS.green,
  cache: COLORS.magenta,
  debug: COLORS.cyan,
};

const enviroment = process?.env?.NODE_ENV || 'development';

export const consoleWithColor = (content, level) => {
  const color = COLORS_BY_TYPE[level] || COLORS.reset;

  if (enviroment === 'development') {
    return console.log(color, content, COLORS.reset);
  }

  // For non-development environments, ensure single-line output
  singleLineLog(content);
};
